.collection {
	// grid
	&.grid {
		.grid-items {
			justify-content: flex-start;

			.item {
				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}

				@include media-breakpoint-up(xl) {
					@include make-col(6);
				}
			}
		}
	}
}

&.landing-page {
	.collection {
		&.grid {
			.grid-items {
				.item {
					@include media-breakpoint-up(lg) {
						@include make-col(4);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(4);
					}
					.card {
						.card-title {
							hyphens: auto;
							@include media-breakpoint-up(xl) {
								// font-size: 40px !important;
							}
						}
					}
				}
			}
		}
	}
}
